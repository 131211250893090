<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal_form">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              {{ modal_title }} Asignación Contable
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalForm"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <label>Imputación</label>
                <input
                  v-model="form.nombre"
                  type="text"
                  class="form-control form-control-sm"
                  :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                  @blur="validaAsignacion"
                />
              </div>
              <div class="col-md-5">
                <label for="bloques">Bloque</label>
                <v-select
                  :class="[
                    $v.form.bloque.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.bloque"
                  placeholder="Bloque"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="$parent.bloques"
                  :filterable="true"
                  @search="$parent.buscarBloques"
                  @input="getSelectBloque"
                  :disabled="!form.nombre"
                ></v-select>
              </div>
              <div class="col-md-3">
                <label for="estado">Estado</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.estado"
                  :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in $parent.listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-md-5">
                <label for="operaciones">Operacion</label>
                <v-select
                  :class="[
                    $v.form.operacion.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.operacion"
                  placeholder="Operacion"
                  label="Noperacion"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.operaciones"
                  @input="getSelectOperacion"
                  :disabled="!form.nombre || !form.bloque"
                ></v-select>
              </div>
              <div class="col-md-5">
                <label for="bloques">Producto</label>
                <v-select
                  :class="[
                    $v.form.producto.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.producto"
                  placeholder="Producto"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.productos"
                  @input="selectProducto"
                  :disabled="!form.nombre || !form.operacion"
                ></v-select>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('tif.asignacionesContables.edit') &&
                !$v.form.$invalid
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "TifAsignacionContableForm",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      modal_title: "",
      form: {},
      listasForms: {
        operaciones: [],
        productos: [],
      },
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        estado: {
          required,
        },
        bloque: {
          required,
        },
        operacion: {
          required,
        },
        producto: {
          required,
        },
        bloque_id: {
          required,
        },
        operacion_id: {
          required,
        },
      },
    };
  },
  methods: {
    getDatosModal(asignacion = null) {
      this.$parent.cargando = true;
      this.resetForm();
      this.method = `POST`;
      this.modal_title = `Crear`;
      if (asignacion) {
        this.modal_title = `Editar`;
        this.method = `PUT`;
        this.form = {
          id: asignacion.id,
          nombre: asignacion.nombre,
          bloque: asignacion.bloque
            ? {
                id: asignacion.bloque.id,
                nombre: asignacion.bloque.nombre,
              }
            : null,
          bloque_id: asignacion.bloque ? asignacion.bloque.id : null,
          estado: asignacion.estado,
          operacion: asignacion.operacion
            ? {
                id: asignacion.operacion.id,
                Noperacion: asignacion.operacion.nOperacion,
              }
            : null,
          operacion_id: asignacion.operacion ? asignacion.operacion.id : null,
          producto: asignacion.producto
            ? {
                id: asignacion.producto.id,
                nombre: asignacion.producto.nombre,
              }
            : null,
          producto_id: asignacion.producto ? asignacion.producto.id : null,
        };
        this.validaAsignacion();
      }
      this.$parent.cargando = false;
    },

    resetForm() {
      this.form = {
        id: null,
        nombre: null,
        bloque: null,
        bloque_id: null,
        estado: null,
        operacion: null,
        operacion_id: null,
        producto: null,
        producto_id: null,
      };
    },

    getTiposProducto() {
      axios
        .get("/api/tif/operaciones/opelistaProd", {
          params: {
            operacion_id: this.form.operacion_id,
          },
        })
        .then((response) => {
          this.cargando = false;
          this.listasForms.productos = response.data;
        });
    },

    getOperacion() {
      axios
        .get("/api/tif/operaciones/lista", {
          params: {
            bloque_id: this.form.bloque_id,
          },
        })
        .then((response) => {
          this.listasForms.operaciones = response.data;
          this.getTiposProducto();
        });
    },

    validaAsignacion() {
      const me = this;
      if (
        !this.$v.form.nombre.$invalid &&
        !this.$v.form.bloque.$invalid && 
        !this.$v.form.operacion.$invalid &&
        !this.$v.form.producto.$invalid
      ) {
        
        axios
          .get("/api/tif/asignacionesContables/validar", {
            params: me.form,
          })
          .then((response) => {
            me.cargando = false;
            if (!response.data) {
              me.form.bloque = null;
              me.form.operacion = null;
              me.form.producto = null;
              me.$swal({
                icon: "error",
                title: `La asignación contable ya existe con los datos consignados.`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch(function(error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getSelectBloque() {
      this.form.bloque_id = null;
      if (this.modal_title == `Editar`) {
        this.form.operacion = null;
        this.form.producto = null;
      }
      if (this.form.bloque) {
        this.form.bloque_id = this.form.bloque.id;
        this.getOperacion();
      }
    },

    getSelectOperacion() {
      this.form.operacion_id = null;
      if (this.form.operacion) {
        this.form.operacion_id = this.form.operacion.id;
        this.getTiposProducto();
      }
    },

    selectProducto() {
      this.form.producto_id = null;
      if (this.form.producto) {
        this.form.producto_id = this.form.producto.id;
        this.validaAsignacion();
      }
    },

    save() {
      this.$parent.cargando = true;
      this.form.bloque_id = this.form.bloque ? this.form.bloque.id : null;

      axios({
        method: this.method,
        url: "/api/tif/asignacionesContables",
        data: this.form,
      })
        .then(() => {
          this.$parent.cargando = false;
          this.$refs.closeModalForm.click();
          this.$parent.getIndex();

          this.$swal({
            icon: "success",
            title: "La asignación contable se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
